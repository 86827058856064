<script>
export default {
  props: {
    isChildMenuActive: {
      type: Boolean,
      default: false,
    },
    openInNewPage: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<template>
    <a
      v-tooltip.right="'Dify'"
      href="https://dify.ai/"
      class="text-slate-700 dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
      :class="{
        'bg-woot-50 dark:bg-slate-800 text-woot-500 hover:bg-woot-50':
         isChildMenuActive,
      }"
      :rel="openInNewPage ? 'noopener noreferrer nofollow' : undefined"
      :target="openInNewPage ? '_blank' : undefined"
    >
      <img
        src="../../../assets/images/dify.png"
        :class="[ 'w-8 h-8', { 'text-woot-500': isChildMenuActive, }]"
      />
      <span class="sr-only">Dify</span>
    </a>
</template>
